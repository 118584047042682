@font-face {
	font-family: 'DFLWeb';
	src: url($path-font + 'DFLWeb-Bd.eot');
	src: url($path-font + 'DFLWeb-Bd.eot?#iefix') format('embedded-opentype'), url($path-font + 'DFLWeb-Bd.woff2') format('woff2'), url($path-font + 'DFLWeb-Bd.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'DFLWeb';
	src: url($path-font + 'DFLWeb-Rg.eot');
	src: url($path-font + 'DFLWeb-Rg.eot?#iefix') format('embedded-opentype'), url($path-font + 'DFLWeb-Rg.woff2') format('woff2'), url($path-font + 'DFLWeb-Rg.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'DFLWeb';
	src: url($path-font + 'DFLWeb-It.eot');
	src: url($path-font + 'DFLWeb-It.eot?#iefix') format('embedded-opentype'), url($path-font + 'DFLWeb-It.woff2') format('woff2'), url($path-font + 'DFLWeb-It.woff') format('woff');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'DFLWeb-Lt';
	src: url($path-font + 'DFLWeb-Lt.eot');
	src: url($path-font + 'DFLWeb-Lt.eot?#iefix') format('embedded-opentype'), url($path-font + 'DFLWeb-Lt.woff2') format('woff2'), url($path-font + 'DFLWeb-Lt.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'DFLWeb-Th';
	src: url($path-font + 'DFLWeb-Th.eot');
	src: url($path-font + 'DFLWeb-Th.eot?#iefix') format('embedded-opentype'), url($path-font + 'DFLWeb-Th.woff2') format('woff2'), url($path-font + 'DFLWeb-Th.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'DFL Condensed';
	src: url($path-font + 'DFLCnWeb-Rg.eot');
	src: url($path-font + 'DFLCnWeb-Rg.eot?#iefix') format('embedded-opentype'), url($path-font + 'DFLCnWeb-Rg.woff2') format('woff2'), url($path-font + 'DFLCnWeb-Rg.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'DFL Condensed';
	src: url($path-font + 'DFLCnWeb-Bd.eot');
	src: url($path-font + 'DFLCnWeb-Bd.eot?#iefix') format('embedded-opentype'), url($path-font + 'DFLCnWeb-Bd.woff2') format('woff2'), url($path-font + 'DFLCnWeb-Bd.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'DFL Display';
	src: url($path-font + 'DFLDisplayWeb-Bl.eot');
	src: url($path-font + 'DFLDisplayWeb-Bl.eot?#iefix') format('embedded-opentype'), url($path-font + 'DFLDisplayWeb-Bl.woff2') format('woff2'), url($path-font + 'DFLDisplayWeb-Bl.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

html {
	--spacing-xs: 2px;
	--spacing-sm: 4px;
	--spacing-md: 6px;
	--spacing-lg: 12px;
	--spacing-xl: 18px;
	--spacing-xxl: 24px;
}

.contentWrapper {
	margin: 0 auto;
	width: $content-width-mobile;
	height: 100%;
}

/**
Animations
 */
@keyframes placeHolderShimmer {
	0% {
		background-position: -468px 0;
	}
	100% {
		background-position: 468px 0;
	}
}

.animated-background {
	display: block;
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: var(--loading-background);
	background: linear-gradient(to right, var(--loading-bar) 8%, var(--loading-bar-highlight) 18%, var(--loading-bar) 33%);

	background-size: 1200px 220px;
	position: relative;
}

@include media-breakpoint-up(md) {
	html {
		--spacing-xs: 2px;
		--spacing-sm: 4px;
		--spacing-md: 8px;
		--spacing-lg: 16px;
		--spacing-xl: 32px;
		--spacing-xxl: 48px;
	}
}

@include media-breakpoint-up(lg) {
	.contentWrapper {
		width: $content-width-desktop;
	}
}

/* You can add global styles to this file, and also import other style files */
// @import "includes/variables";
@import '@nx-bundesliga/bundesliga-com-styles/_themes';
@import '@nx-bundesliga/bundesliga-com-styles/_variables';
$grid-gutter-width: 16px;
@import 'styles-page-structure';
@import 'vendor/styles-bootstrap';
@import 'vendor/styles-material';
@import 'tippy.js/dist/tippy.css';
@import 'overlayscrollbars/styles/overlayscrollbars.css';
@import '@nx-bundesliga/bundesliga-com-styles/_typo';
@import '@nx-bundesliga/bundesliga-com-styles/_grid';

html {
	box-sizing: border-box;
	height: 100%;
}
body {
	margin: 0 !important;
	height: 100%;
	font-family: 'DFLWeb';
	color: var(--type-body-soft);
}
*,
*:before,
*:after {
	box-sizing: border-box;
}
a:hover,
a:focus {
	color: $link-hover-color;
	text-decoration: none;
}

a:active,
a:hover {
	outline: 0;
}
a {
	color: $link-color;
	text-decoration: none;
}

// ** Library overrides ** //
nx-bundesliga-bundesmaster-ui-teaser-preview {
	background-color: transparent !important;
}
nx-bundesliga-bundesmaster-ui-mat-paginator-goto {
	.goto {
		background-color: transparent !important;
	}
	.selection-container {
		background-color: transparent !important;
		.selection-label {
			color: var(--mat-menu-item-icon-color) !important;
		}
	}
}

@use '@angular/material' as mat;
@import '@nx-bundesliga/bundesliga-com-styles/variables';
@import '@nx-bundesliga/bundesliga-com-styles/variables-material';
@import '@nx-bundesliga/bundesliga-com-styles/mixins';

$fc-font-family: 'DFLWeb';
$fontConfig: mat.m2-define-typography-config(
	$font-family: $fc-font-family,
	$headline-1:
		mat.m2-define-typography-level(
			$font-size: 34px,
			$line-height: 40px,
			$font-weight: 400,
			$font-family: $fc-font-family,
			$letter-spacing: 0.0074em
		),
	$headline-2:
		mat.m2-define-typography-level(
			$font-size: 45px,
			$line-height: 48px,
			$font-weight: 400,
			$font-family: $fc-font-family,
			$letter-spacing: 0em
		),
	$headline-3:
		mat.m2-define-typography-level(
			$font-size: 56px,
			$line-height: 56px,
			$font-weight: 400,
			$font-family: $fc-font-family,
			$letter-spacing: -0.0089em
		),
	$headline-4:
		mat.m2-define-typography-level(
			$font-size: 112px,
			$line-height: 112px,
			$font-weight: 300,
			$font-family: $fc-font-family,
			$letter-spacing: -0.0134em
		),
	//$headline-5: mat.define-typography-level(px),
	//$headline-6: mat.define-typography-level(px),
	$subtitle-1:
		mat.m2-define-typography-level(
			$font-size: 15px,
			$line-height: 24px,
			$font-weight: 500,
			$font-family: $fc-font-family,
			$letter-spacing: 0.0067em
		),
	$subtitle-2:
		mat.m2-define-typography-level(
			$font-size: 16px,
			$line-height: 28px,
			$font-weight: 400,
			$font-family: $fc-font-family,
			$letter-spacing: 0.0094em
		),
	$body-1:
		mat.m2-define-typography-level(
			$font-size: 16px,
			$line-height: 20px,
			$font-weight: 400,
			$font-family: $fc-font-family,
			$letter-spacing: 0.0179em
		),
	$body-2:
		mat.m2-define-typography-level(
			$font-size: 16px,
			$line-height: 24px,
			$font-weight: 500,
			$font-family: $fc-font-family,
			$letter-spacing: 0.0179em
		),
	$caption:
		mat.m2-define-typography-level(
			$font-size: 12px,
			$line-height: 20px,
			$font-weight: 400,
			$font-family: $fc-font-family,
			$letter-spacing: 0.0333em
		),
	$button:
		mat.m2-define-typography-level(
			$font-size: 14px,
			$line-height: 14px,
			$font-weight: 500,
			$font-family: $fc-font-family,
			$letter-spacing: 0.0893em
		),
	$overline:
		mat.m2-define-typography-level(
			$font-size: 20px,
			$line-height: 32px,
			$font-weight: 500,
			$font-family: $fc-font-family,
			$letter-spacing: 0.0075em
		)
);
/*$fontConfig2: mat.define-typography-config(
  $font-family: 'DFLWeb',
  $headline-1: mat.define-typography-level(34px),
  $headline-2: mat.define-typography-level(45px),
  $headline-3: mat.define-typography-level(56px),
  $headline-4: mat.define-typography-level(112px),
  //$headline-5: mat.define-typography-level(px),
  //$headline-6: mat.define-typography-level(px),
  $subtitle-1: mat.define-typography-level(15px),
  $subtitle-2: mat.define-typography-level(16px),
  $body-1: mat.define-typography-level(14px),
  $body-2: mat.define-typography-level(14px),
  $caption: mat.define-typography-level(12px),
  $button: mat.define-typography-level(14px),
  $overline: mat.define-typography-level(20px)
);*/

// Foreground Elements

// Light Theme Text
$dark-text: #000e14;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$light-theme-foreground-palette: (
	base: black,
	divider: $dark-dividers,
	dividers: $dark-dividers,
	disabled: $dark-disabled-text,
	disabled-button: rgba($dark-text, 0.26),
	disabled-text: $dark-disabled-text,
	elevation: black,
	secondary-text: $dark-accent-text,
	hint-text: $dark-disabled-text,
	//accent-text: $dark-accent-text,
	icon: $dark-accent-text,
	icons: $dark-accent-text,
	text: $dark-primary-text,
	slider-min: $dark-primary-text,
	slider-off: rgba($dark-text, 0.26),
	slider-off-active: $dark-disabled-text
);

// Dark Theme text
$light-text: #c9cccf;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$dark-theme-foreground-palette: (
	base: $light-text,
	divider: $light-dividers,
	dividers: $light-dividers,
	disabled: $light-disabled-text,
	disabled-button: rgba($light-text, 0.3),
	disabled-text: $light-disabled-text,
	elevation: black,
	hint-text: $light-disabled-text,
	secondary-text: $light-accent-text,
	//accent-text: $light-accent-text,
	icon: $light-text,
	icons: $light-text,
	text: $light-text,
	slider-min: $light-text,
	slider-off: rgba($light-text, 0.3),
	slider-off-active: rgba($light-text, 0.3)
);

// Background config
// Light bg
$light-background: #ffffff;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#191e24, 20%);
$dark-bg-alpha-4: rgba(#191e24, 0.04);
$dark-bg-alpha-12: rgba(#191e24, 0.12);

$light-theme-background-palette: (
	background: $light-background,
	status-bar: $light-bg-darker-20,
	app-bar: $light-bg-darker-5,
	hover: $dark-bg-alpha-4,
	card: $light-bg-lighter-5,
	dialog: $light-bg-lighter-5,
	tooltip: $dark-bg-tooltip,
	disabled-button: $dark-bg-alpha-12,
	raised-button: $light-bg-lighter-5,
	focused-button: $dark-focused,
	selected-button: $light-bg-darker-20,
	selected-disabled-button: $light-bg-darker-30,
	disabled-button-toggle: $light-bg-darker-10,
	unselected-chip: $light-bg-darker-10,
	disabled-list-option: $light-bg-darker-10
);

// Dark bg
$dark-background: #191e24;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#ffffff, 0.04);
$light-bg-alpha-12: rgba(#ffffff, 0.12);

// Background palette for dark themes.
$dark-theme-background-palette: (
	background: $dark-background,
	status-bar: $dark-bg-lighter-20,
	app-bar: $dark-bg-lighter-5,
	hover: $light-bg-alpha-4,
	card: $dark-bg-lighter-5,
	dialog: $dark-bg-lighter-5,
	tooltip: $dark-bg-lighter-20,
	disabled-button: $light-bg-alpha-12,
	raised-button: $dark-bg-lighter-5,
	focused-button: $light-focused,
	selected-button: $dark-bg-lighter-20,
	selected-disabled-button: $dark-bg-lighter-30,
	disabled-button-toggle: $dark-bg-lighter-10,
	unselected-chip: $dark-bg-lighter-20,
	disabled-list-option: $dark-bg-lighter-10
);

// Compute font config
@include mat.core();

// Theme Config

body {
	--primary-color: #c80a00;
	--primary-lighter-color: #efb6b3;
	--primary-darker-color: #b40500;
	--text-primary-color: #{$light-primary-text};
	--text-primary-lighter-color: #{$dark-primary-text};
	--text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (
	main: #c80a00,
	lighter: #efb6b3,
	darker: #b40500,
	200: #c80a00,
	// For slide toggle,
	contrast:
		(
			main: $light-primary-text,
			lighter: $dark-primary-text,
			darker: $light-primary-text
		)
);
$theme-primary: mat.m2-define-palette($mat-primary, main, lighter, darker);

body {
	--accent-color: #000e14;
	--accent-lighter-color: #b3b7b9;
	--accent-darker-color: #00080b;
	--text-accent-color: #{$light-primary-text};
	--text-accent-lighter-color: #{$dark-primary-text};
	--text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
	main: #000e14,
	lighter: #b3b7b9,
	darker: #00080b,
	200: #000e14,
	// For slide toggle,
	contrast:
		(
			main: $light-primary-text,
			lighter: $dark-primary-text,
			darker: $light-primary-text
		)
);
$theme-accent: mat.m2-define-palette($mat-accent, main, lighter, darker);

body {
	--warn-color: #d32f2f;
	--warn-lighter-color: #f2c1c1;
	--warn-darker-color: #c21d1d;
	--text-warn-color: #{$light-primary-text};
	--text-warn-lighter-color: #{$dark-primary-text};
	--text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
	main: #d32f2f,
	lighter: #f2c1c1,
	darker: #c21d1d,
	200: #d32f2f,
	// For slide toggle,
	contrast:
		(
			main: $light-primary-text,
			lighter: $dark-primary-text,
			darker: $light-primary-text
		)
);
$theme-warn: mat.m2-define-palette($mat-warn, main, lighter, darker);

$theme: mat.m2-define-light-theme(
	(
		color: (
			primary: $theme-primary,
			accent: $theme-accent,
			warn: $theme-warn
		),
		typography: $fontConfig,
		density: 0
	)
);

$altTheme: mat.m2-define-dark-theme(
	(
		color: (
			primary: $theme-primary,
			accent: $theme-accent,
			warn: $theme-warn
		),
		typography: $fontConfig,
		density: 0
	)
);

/*
// Theme Init
@include angular-material-theme($theme);

.force-dark,
html[dark="1"] {
  @include angular-material-theme($altTheme);
}*/
$altTheme-checkbox: mat.m2-define-dark-theme(
	(
		color: (
			primary: $theme-primary,
			accent: mat.m2-define-palette(mat.$m2-grey-palette, 800, 700, 900),
			warn: $theme-warn
		),
		typography: mat.m2-define-typography-config($fontConfig),
		density: 0
	)
);

$theme-slidetoggle: mat.m2-define-light-theme(
	(
		color: (
			primary: $theme-primary,
			accent: mat.m2-define-palette(mat.$m2-green-palette, 800, 700, 900),
			warn: $theme-warn
		),
		typography: $fontConfig,
		density: 0
	)
);

@include mat.core-theme($theme);
@include mat.all-component-themes($theme);
@include mat.all-component-typographies($fontConfig);
/*@include mat.autocomplete-theme($theme);
@include mat.button-theme($theme);
@include mat.fab-theme($theme);
@include mat.button-toggle-theme($theme);
@include mat.button-typography($fontConfig);
@include mat.checkbox-theme($theme);
@include mat.input-theme($theme);
@include mat.input-typography($fontConfig);
@include mat.slide-toggle-theme($theme-slidetoggle);
@include mat.select-theme($theme);
@include mat.dialog-theme($theme);
@include mat.form-field-theme($theme);
@include mat.form-field-typography($fontConfig);
@include mat.datepicker-theme($theme);
@include mat.tooltip-theme($theme);
@include mat.tooltip-typography($fontConfig);
@include mat.progress-spinner-theme($theme);
@include mat.menu-theme($theme);
@include mat.menu-typography($fontConfig);
@include mat.stepper-theme($theme);
@include mat.stepper-typography($fontConfig);
@include mat.expansion-theme($theme);
@include mat.sidenav-theme($theme);
@include mat.tabs-theme($theme);*/

.force-dark,
html[dark='1'] {
	@include mat.core-color($altTheme);
	@include mat.all-component-colors($altTheme);
	/*	@include mat.autocomplete-color($altTheme);
	@include mat.button-color($altTheme);
	@include mat.fab-color($altTheme);
	@include mat.button-toggle-color($altTheme);
	@include mat.checkbox-color($altTheme-checkbox);
	@include mat.input-color($altTheme);
	@include mat.slide-toggle-color($altTheme);
	@include mat.dialog-color($altTheme);
	@include mat.legacy-form-field-color($altTheme);
	@include mat.form-field-color($altTheme);
	@include mat.select-color($altTheme);
	@include mat.datepicker-color($altTheme);
	@include mat.tooltip-color($altTheme);
	@include mat.progress-spinner-color($altTheme);
	@include mat.menu-color($altTheme);
	@include mat.stepper-color($altTheme);
	@include mat.expansion-color($altTheme);
	@include mat.sidenav-color($altTheme);
	@include mat.tabs-color($altTheme);*/
}

// Specific component overrides, pieces that are not in line with the general theming

/**
 * Buttons
 */
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
	padding: 1px 16px;
	//  margin: 0 .65em;
	//  min-width: 3em;
	//  line-height: 36.4px
}
.mat-stroked-button {
	&:not(.mat-button-disabled) {
		border-color: $PremiumBlack;
	}
}

/**
 * Slide Toggle Styles
 */
html[dark='1'] mat-slide-toggle.mat-mdc-slide-toggle,
mat-slide-toggle.mat-mdc-slide-toggle,
.mat-mdc-slide-toggle.mat-accent {
	--mdc-elevation-overlay-opacity: 1;
	--mdc-switch-unselected-track-color: #71757a;
	--mdc-switch-selected-track-color: var(--dfl-green);

	--mdc-switch-selected-handle-color: white;
	--mdc-switch-unselected-handle-color: white;
	--mdc-switch-handle-surface-color: white;

	--mdc-switch-handle-height: 17px;
	--mdc-switch-handle-width: 17px;
	--mdc-switch-track-height: 22px;
	--mdc-switch-track-shape: 11px;
	--mdc-switch-track-width: 37px;

	--mdc-switch-selected-focus-state-layer-color: #43a047;
	--mdc-switch-selected-hover-state-layer-color: #43a047;
	--mdc-switch-selected-pressed-state-layer-color: #43a047;
	--mdc-switch-selected-focus-handle-color: white;
	--mdc-switch-selected-hover-handle-color: white;
	--mdc-switch-selected-pressed-handle-color: white;
	--mdc-switch-selected-focus-track-color: var(--dfl-green);
	--mdc-switch-selected-hover-track-color: var(--dfl-green);
	--mdc-switch-selected-pressed-track-color: var(--dfl-green);

	--mdc-switch-unselected-focus-state-layer-color: #43a047;
	--mdc-switch-unselected-hover-state-layer-color: #43a047;
	--mdc-switch-unselected-pressed-state-layer-color: #43a047;
	--mdc-switch-unselected-focus-handle-color: white;
	--mdc-switch-unselected-hover-handle-color: white;
	--mdc-switch-unselected-pressed-handle-color: white;
	--mdc-switch-unselected-focus-track-color: #71757a;
	--mdc-switch-unselected-hover-track-color: #71757a;
	--mdc-switch-unselected-pressed-track-color: #71757a;

	transition: all 0.3s;
	width: 100%;
	padding: 0 5px;
	height: 100%;

	label {
		font-family: $font-family-sans-serif;
		white-space: normal;
		padding-left: 8px;
	}

	.mdc-switch__handle {
		left: 2px;
		.mdc-switch__icons {
			display: none;
		}
	}
	.mdc-switch--selected {
		.mdc-switch__handle {
			left: -2px;
		}
	}
}

/**
 * Mat Checkbox style
 */
mat-checkbox {
	--mdc-checkbox-state-layer-size: 40px;
	--mdc-checkbox-ripple-size: 40px;
	.mdc-checkbox__background {
		width: 24px;
		height: 24px;
	}
	&.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
		top: calc((var(--mdc-checkbox-state-layer-size, 40px) - 24px) / 2) !important;
		left: calc((var(--mdc-checkbox-state-layer-size, 40px) - 24px) / 2) !important;
	}
	label {
		margin-bottom: 0;
		white-space: normal;
	}
}

/**
  * Mat Select, Mat Option
  */

.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
	background: rgba(0, 0, 0, 0.04) !important;
}

html[dark='1'] mat-option {
	background: var(--background-card-soft) !important;
	color: var(--type-body-soft);
}

html[dark='1'] mat-option.mat-active,
html[dark='1'] mat-option.mat-selected {
	color: var(--type-body-hover) !important;
}

.cdk-overlay-container {
	.cdk-overlay-backdrop {
		&.video-backdrop {
			background: rgba(0, 0, 0, 0.8);

			&:after {
				content: ' ';
				background-image: url('/assets/jwplayer/goalbar/close.svg');
				width: 32px;
				height: 32px;
				position: absolute;
				top: 16px;
				right: 16px;
				cursor: pointer;
			}
		}
	}
}

.cdk-overlay-pane {
	&.video-player {
		height: auto;
		width: calc(100% - 132px);
		margin: auto auto;
		max-width: calc(((100vh - 132px) * 1.77) - 104px) !important;
		@media (max-width: 575px) {
			width: calc(100% - 32px);
			max-width: calc(((100vh - 32px) * 1.77) - 104px) !important;
		}
		mat-dialog-container {
			--mdc-dialog-container-color: transparent;
		}
		.mat-mdc-dialog-container .mdc-dialog__surface {
			box-shadow: none;
		}
		mat-dialog-content {
			font-family: 'DFL Condensed';
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			position: relative;
			height: 100%;
			margin: 0 !important;
			padding: 0 !important;
			overflow: hidden;
			width: 100%;
			max-width: 100%;
			max-height: 100%;

			dfl-jw-player {
				height: auto;
				display: block;
				width: 100%;
				max-width: 100%;

				::ng-deep .jwplayer.jw-skin-goalbar {
					.jw-title,
          .jw-controls .jw-settings-menu,
          .jw-controls .jw-nextup-container,
            //.jw-controls .jw-controlbar .jw-slider-time,
          .jw-controls .jw-controlbar .jw-button-container .jw-related-shelf-container,
            //.jw-controls .jw-controlbar .jw-button-container .jw-icon-playback,
            //.jw-controls .jw-controlbar .jw-button-container .jw-icon-next,
          .jw-controls .jw-controlbar .jw-button-container .jw-text-duration,
          .jw-controls .jw-controlbar .jw-button-container .jw-text-elapsed,
          .jw-controls .jw-controlbar .jw-button-container .jw-text-countdown,
          .jw-controls .jw-controlbar .jw-button-container .jw-icon-cast,
            //.jw-controls .jw-controlbar .jw-button-container .jw-settings-submenu-button,
          .jw-controls .jw-controlbar .jw-button-container .jw-related-btn,
          .afs_ads {
						display: none;
					}
				}
			}
		}
	}
}

/**
  Datepicker Masked inputs
 */
.mat-datepicker-input {
	background-color: transparent;
	border: 0;
	width: 100%;
	outline: 0;
	color: var(--type-body-hover);
}

/**
  Forms
 */

mat-form-field {
	&.mat-form-field-appearance-fill .mat-mdc-form-field-flex {
		//height: 48px !important;
	}
}
.force-dark,
html[dark='1'] {
	.mdc-text-field--filled:not(.mdc-text-field--disabled) {
		background-color: transparent;
	}
}

// fix hints and errors below inputs inside form fields
.mat-mdc-form-field-subscript-dynamic-size .mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-subscript-dynamic-size .mat-mdc-form-field-error-wrapper {
	min-height: 23px;
}

html:not([dark='1']) {
	// disable all box-shadows for dark mode
	@each $name, $shadow in $elevations {
		.elevation-#{$name} {
			@include elevation($name);
		}
	}

	@each $name, $transition in $elevation-transitions {
		.elevation-t-#{$name} {
			@include elevation-t($name);
		}
	}
}
